// components/RouteGuard.tsx
"use client";

import { useAtomValue } from "jotai";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { IN_HOUSE_URL } from "src/models/_common/urls";
import { academiaFFAtom } from "./flag.store";

const ON_SALE_URLS = {
  "/online/special/codingtest": "/product/33",
  "/online/web_basic": "/product/46",
  "/online/special/rtan_run": "/product/25",
  "/online/special/game_survivor": "/product/3",
  "/online/special/dev_strategy": "/product/31",
  "/online/special/dev_turnover": "/product/32",
  "/online/developer101": "/product/47",
  "/online/javascript_start": "/product/6",
  "/online/finance_automation": "/product/45",
  "/online/nocode": "/product/48",
  "/online/nocode_ai_app": "/product/28",
  "/online/special/1h_portfolio": "/product/29",
  "/online/data_v2": "/product/49",
  "/online/IT_101": "/product/27",
  "/online/special/developer_roadmap": "/product/26",
  "/online/algorithm": "/product/7",
  "/online/special/androidvsios": "/product/50",
  "/online/sql_2023": "/product/8",
  "/online/special/chatgpt_practical_use": "/product/9",
  "/online/spring_v2": "/product/10",
  "/online/web_chatgp": "/product/51",
  "/online/basicfigma": "/product/52",
  "/online/special/unemployed": "/product/11",
  "/online/workflow_automation": "/product/13",
  "/online/special/flutterflow_instagram": "/product/14",
  "/online/cloud_aws": "/product/15",
  "/online/special/code_review": "/product/16",
  "/online/3d_interactive_web": "/product/17",
  "/online/ai_analytics_blockchain": "/product/18",
  "/online/generative_ai": "/product/44",
  "/online/monthly/ai_report_automation": "/product/19",
  "/online/nocode_ai_automation": "/product/20",
  "/online/gpt_300_online": "/product/34",
  "/online/cicd": "/product/21",
  "/online/marketing_ga4": "/product/22",
  "/online/bio_app": "/product/23",
  "/online/3d_game": "/product/24",
  "/online/app": "/product/57",
  "/online/special/ai-surviving": "/product/60",
};

const REVERSE_ON_SALE_URLS = Object.entries(ON_SALE_URLS).reduce(
  (acc, [bpUrl, apUrl]) => {
    acc[apUrl] = bpUrl;
    return acc;
  },
  {} as Record<string, string>,
);
const BP_URLS = Object.keys(ON_SALE_URLS);
const AP_URLS = Object.values(ON_SALE_URLS);

const getRedirectStatus = (isOn: boolean, pathname: string) => {
  if (isOn === false && pathname === "/catalog/scc") return "수업탐색으로 이동";
  if (isOn === false && pathname.startsWith("/product/")) {
    if (AP_URLS.includes(pathname)) return "강의상세로 이동";
    return "얼럿 띄우고 수업탐색으로 이동";
  }
  if (isOn === true && pathname === "/catalog") return "상품탐색으로 이동";
  if (isOn === true && pathname.startsWith("/online/")) {
    if (BP_URLS.includes(pathname)) return "상품상세로 이동";
    return "얼럿 띄우고 상품탐색으로 이동";
  }
  return null;
};

export function useRouterGuard() {
  const router = useRouter();
  const pathname = usePathname();

  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  const createUrlWithUtm = (targetPath: string) => {
    if (typeof window === "undefined") return targetPath;

    const currentUrl = new URL(window.location.href);
    const targetUrl = new URL(targetPath, window.location.origin);

    currentUrl.searchParams.forEach((value, key) => {
      targetUrl.searchParams.set(key, value);
    });

    return targetUrl.pathname + targetUrl.search;
  };

  useEffect(() => {
    if (isLoading) return;
    const redirectStatus = getRedirectStatus(isOn, pathname);
    if (redirectStatus === "얼럿 띄우고 수업탐색으로 이동") {
      alert("판매 중인 상품이 아닙니다.");
      return router.replace(createUrlWithUtm(IN_HOUSE_URL.수업탐색));
    }
    if (redirectStatus === "얼럿 띄우고 상품탐색으로 이동") {
      alert("판매 중인 상품이 아닙니다.");
      return router.replace(createUrlWithUtm(IN_HOUSE_URL.상품탐색));
    }
    if (redirectStatus === "강의상세로 이동") {
      return router.replace(createUrlWithUtm(REVERSE_ON_SALE_URLS[pathname]));
    }
    if (redirectStatus === "상품상세로 이동") {
      return router.replace(createUrlWithUtm(ON_SALE_URLS[pathname]));
    }

    if (redirectStatus === "수업탐색으로 이동") {
      return router.replace(createUrlWithUtm(IN_HOUSE_URL.수업탐색));
    }
    if (redirectStatus === "상품탐색으로 이동") {
      return router.replace(createUrlWithUtm(IN_HOUSE_URL.상품탐색));
    }
  }, [isOn, pathname, isLoading]);
}
