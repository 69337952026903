import { BootCamp } from "./BootCamp/BootCamp";
import BannerCarousel from "./Carousel/BannerCarousel";
import { CourseRecommend } from "./CourseRecommend/CourseRecommend";
import { Hero } from "./Hero/Hero";
import { KdcRecommend } from "./KdcRecommend/KdcRecommend";
import { ProductRecommend } from "./ProductRecommend/ProductRecommend";
import { SpartaStartToEnd } from "./SpartaStartToEnd/SpartaStartToEnd";
import { Strength } from "./Strength/Strength";

export const HomeV4 = {
  Course: CourseRecommend,
  Product: ProductRecommend,
  Hero: Hero,
  BannerCarousel: BannerCarousel,
  BootCamp: BootCamp,
  SpartaStartToEnd: SpartaStartToEnd,
  Strength: Strength,
  KdcRecommend: KdcRecommend,
};
