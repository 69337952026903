import { useRef } from "react";
import * as S from "./recommendCards.style";
import { CourseCard } from "../CourseCard/CourseCard";
import { Scrollbar } from "src/components/atoms/Scrollbar/Scrollbar";
import { CourseCardType } from "src/models/home/homeV4.models";
interface CourseCard {
  title: string;
  // ... 기타 필요한 카드 속성들
}

interface RecommendCardsProps {
  title: string;
  description: string;
  cards: CourseCardType[];
  columnCount?: number;
}

export const RecommendCards = ({
  title,
  description,
  cards,
  columnCount = 4,
}: RecommendCardsProps) => {
  const cardContainerRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{description}</S.SubTitle>
      </S.TextContainer>

      <S.MobileCardWrap>
        <S.CardContainer ref={cardContainerRef}>
          {cards.map((card, index) => (
            <CourseCard key={`card-${card.title}-${index}`} courseCard={card} />
          ))}
        </S.CardContainer>
        <Scrollbar
          containerRef={cardContainerRef}
          scrollBarWidth={70}
          scrollBarPointerWidth={41}
        />
      </S.MobileCardWrap>

      <S.DesktopCardWrap columnCount={columnCount}>
        {cards.map((card, index) => (
          <CourseCard key={`card-${card.title}-${index}`} courseCard={card} />
        ))}
      </S.DesktopCardWrap>
    </S.Container>
  );
};
