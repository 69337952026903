// @ts-check

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getCookie } from "cookies-next";

interface ErrorResponse {
  apiMessage: string;
  id: string;
  timestamp: string;
}

type CustomError = AxiosError<ErrorResponse>;

const apiUrl = process.env.ONLINE_API_V3_URL;
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": `application/json`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: CustomError) => {
    console.error(error);
    return Promise.reject(error);
  },
);

interface AxiosOptions {
  params?: Record<string, unknown>;
  data?: unknown;
  headers?: Record<string, string>;
  timeout?: number;
}

type HttpMethod = "get" | "post" | "put" | "patch" | "delete";

const request = async <T>(
  method: HttpMethod,
  endpoint: string,
  options: AxiosOptions = {},
): Promise<T> => {
  const { params, data, headers = {}, timeout } = options;
  Object.assign(headers, {
    Authorization: `Bearer ${getCookie("token")}`,
  });

  const config: AxiosRequestConfig = {
    method,
    url: endpoint,
    params,
    headers,
    timeout,
    data,
  };

  const response = await axiosInstance.request(config);
  return response.data;
};

// baseURL: ONLINE_V3_API
const PrivateAxios = <T>(
  method: HttpMethod,
  endpoint: string,
  options?: AxiosOptions,
): Promise<T> => request<T>(method, endpoint, options);

export default PrivateAxios;
