import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";
import { useCardColumnCount } from "src/businesslogics/catalog/getCardColumnCount";
import { CourseRecommendType } from "src/models/home/homeV4.models";
import { RecommendCards } from "src/components/molecules/home/v4/RecommendCards/RecommendCards";

export const CourseRecommend = () => {
  const courseData: CourseRecommendType = useCustomRemoteConfig(
    "scc_main_recommend_course_cards",
    "json"
  );

  const cardColumnCount = useCardColumnCount();

  return (
    courseData && (
      <RecommendCards
        title={courseData.title}
        description={courseData.description}
        cards={courseData.courseCards}
        columnCount={cardColumnCount}
      />
    )
  );
};
