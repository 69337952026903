import styled from "@emotion/styled";
import { mTitle2 } from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";
import { gnbMobileHeight, zIndexGnb } from "../../../../styles/variables";

export const Wrapper = styled.div<{ isTabOpened: boolean; fixed: boolean }>`
  position: fixed;
  z-index: ${zIndexGnb};
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  width: 100%;

  ${({ isTabOpened, fixed }) =>
    !isTabOpened &&
    `
      top: 0;
      max-height: 50px;
      transform: ${fixed ? `translateY(0px)` : `translateY(-50px)`};
      transition: transform 300ms;
    `}

  ${({ isTabOpened }) => isTabOpened && `position: fixed !important;`}

  // 기존 페이지의 style_old.css override 방지용
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;

export const TopMenuBar = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  height: ${gnbMobileHeight}px;
  padding: 0 16px;
  z-index: ${zIndexGnb};
  background-color: white;

  flex-shrink: 0;
`;

export const BtnWrapper = styled.button`
  display: flex;
  align-items: center;
  width: 54px;
`;

export const LeftBtnWrapper = styled(BtnWrapper)`
  justify-content: flex-start;
  width: fit-content;
`;

export const RightBtnWrapper = styled(BtnWrapper)`
  justify-content: flex-end;
`;

export const PageName = styled.div`
  ${mTitle2}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MypageBtn = styled.div``;

export const GnbCenterContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;
export const GnbRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const CatalogSearchWrapper = styled.div`
  display: block;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const HamburgerSVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  margin-right: 18px;
`;

export const SearchLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
