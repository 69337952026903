import * as S from "./GnbMyPage.style";
import { useAtom } from "jotai";
import { NextImageWrapper } from "../../../atoms/NextImageWrapper";
import { useUserInfo } from "../../../../businesslogics/_common/auth";
import { GnbRowMobile } from "../atoms/GnbRow.mobile";
import { isMyPageOpenAtom } from "../../../../stores/_layout/gnb";
import { useIsLoggedInV2 } from "../../../../businesslogics/_common/login";
import { useEffect, useRef } from "react";
import { useOnOutsideClick } from "../../../../businesslogics/events/useOnOutsideClick";
import { showLineBannerAtom } from "../../../../stores/showLineBannerAtom";
import { CouponIcon, PointIcon } from "./GnbMyPage.svg";
import { logoutAnchor, mobileMyPageAnchors } from "../models/gnbV3.models";
import { useClickAnchor } from "../../../../businesslogics/_layout/gnb/v2";
import {
  couponAnchor,
  pointAnchor,
} from "../../../../models/_layout/gnbV2.models";
import { isLoginModalShowAtom } from "../../../../stores/_common/modal";
import { useUserMetaQuery } from "../../../../queries/gnb";
import { useCouponCountText } from "../../../../businesslogics/academia/useFeatureFlag";

export const GnbMyPage = () => {
  const userMetaData = useUserMetaQuery();
  const onClickAnchor = useClickAnchor();
  const [show, setIsMyPageOpenAtom] = useAtom(isMyPageOpenAtom);
  const isLoggedIn = useIsLoggedInV2();
  const userName = useUserInfo("name");
  const userEmail = useUserInfo("email");
  const myPageRef = useRef(null);
  useOnOutsideClick(myPageRef, () => setIsMyPageOpenAtom(false));
  const [showLineBanner] = useAtom(showLineBannerAtom);
  const [, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const couponCount = useCouponCountText();

  useEffect(() => {
    if (!isLoggedIn && show) {
      setIsLoginModalShow(true);
    }
  }, [isLoggedIn]);

  const imgLink = `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/profile/developer.png`;

  return (
    <S.ToolTipBox
      show={show}
      isShort={!isLoggedIn}
      ref={myPageRef}
      showLineBanner={showLineBanner}
    >
      <S.MyPageContainer>
        <S.GreetingContainer>
          {isLoggedIn && (
            <>
              <S.UserInfoWrapper>
                <NextImageWrapper
                  src={imgLink}
                  mobileWidth={40}
                  mobileHeight={40}
                  borderRadius={"100%"}
                />
                <S.ProfileContainer>
                  <S.UserName>{userName}</S.UserName>
                  <S.EmailContainer>{userEmail}</S.EmailContainer>
                </S.ProfileContainer>
              </S.UserInfoWrapper>
              <S.PointCouponContainer>
                <S.PointCouponWrapper
                  onClick={() => onClickAnchor(pointAnchor)}
                >
                  <S.TitleWrapper>
                    <PointIcon />
                    <S.PointCouponTitle>포인트</S.PointCouponTitle>
                  </S.TitleWrapper>
                  <S.PointCouponContent>
                    {userMetaData.isLoading
                      ? "로딩중"
                      : userMetaData.data?.point}
                  </S.PointCouponContent>
                </S.PointCouponWrapper>
                <S.ReversDivider />
                <S.PointCouponWrapper
                  onClick={() => onClickAnchor(couponAnchor)}
                >
                  <S.TitleWrapper>
                    <CouponIcon />
                    <S.PointCouponTitle>쿠폰</S.PointCouponTitle>
                  </S.TitleWrapper>
                  <S.PointCouponContent>{couponCount}</S.PointCouponContent>
                </S.PointCouponWrapper>
              </S.PointCouponContainer>
            </>
          )}
        </S.GreetingContainer>

        <S.RowsContainer>
          <S.Divider />
          <S.Group>
            {mobileMyPageAnchors.map((anchor) => (
              <div key={`gnb-mypage-mobile-${anchor.key}`}>
                {isLoggedIn && (
                  <div key={`gnb-mypage-mobile-${anchor.key}`}>
                    <GnbRowMobile anchor={anchor} />
                  </div>
                )}
              </div>
            ))}
            {/* <Link
              href={`${process.env.BASE_URL}/nb/guide`}
              onClick={() => {
                sendCPLog("scc_myPage_click", {
                  button_text: "국비지원 신청 가이드",
                });
              }}
            >
              <S.MyPageBanner>
                <S.BanenrContainer>
                  <S.BannerIconWrapper>
                    <ZeroWonIcon />
                  </S.BannerIconWrapper>
                  <S.BannerTextContainer>
                    <S.BannerSubText>50만원 강의를 0원으로?</S.BannerSubText>
                    <S.BannerText>국비지원 신청 가이드 보기</S.BannerText>
                  </S.BannerTextContainer>
                </S.BanenrContainer>
                <ArrowChevronRightSmall size={16} color={"#9da7ae"} />
              </S.MyPageBanner>
            </Link> */}
          </S.Group>
          <S.Divider />

          <S.Group>
            <GnbRowMobile
              key={`gnb-mypage-mobile-${logoutAnchor.key}`}
              anchor={logoutAnchor}
            />
          </S.Group>
        </S.RowsContainer>
      </S.MyPageContainer>
    </S.ToolTipBox>
  );
};
