import { logout } from "../../../../businesslogics/_common/auth";

export type Anchor = {
  key: string;
  name: string;
  href?: string;
  onClick?: Function;
  className?: string;
  isLoginNeededToNavigate?: boolean;
  brand?: string;
  subTitle?: string;
  tag?: string;
  mobileText?: string;
  isSub?: boolean;
};

export const sccAnchor: Anchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: "/",
  brand: "온라인",
};

const catalogAnchor: Anchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: "/catalog/scc",
  brand: "온라인",
};

const kdtAnchor = {
  key: "kdt",
  name: "부트캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "온라인",
  tag: "국비",
};

const intellipickMobileAnchor: Anchor = {
  key: "intellipick",
  name: "인텔리픽",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
  mobileText: "신입 개발자 채용 공고를 한 곳에서",
};

export const blogAnchor: Anchor = {
  key: "blog",
  name: "블로그",
  href: "/blog",
  brand: "블로그",
};

export const communityAnchor: Anchor = {
  key: "community",
  name: "커뮤니티",
  href: "/community",
  brand: "커뮤니티",
};

export const eventAnchor: Anchor = {
  key: "event",
  name: "이벤트",
  href: "/event",
  brand: "이벤트",
};

export const exhibitionAnchor: Anchor = {
  key: "exhibition",
  name: "수강생 작품",
  href: "/exhibition",
  brand: "수강생 작품",
};

export const faqAnchor: Anchor = {
  key: "faq",
  name: "고객센터",
  href: "https://support.spartacodingclub.kr/",
};

export const BizAnchor: Anchor = {
  key: "bisness",
  name: "기업 서비스",
  onClick: () => {},
  brand: "기업 서비스",
};

export const bizEducationAnchor: Anchor = {
  key: "bizEducation",
  name: "기업 교육",
  href: "https://b2b.spartacodingclub.kr/",
  brand: "기업 교육",
  isSub: true,
};

export const bizOutsourcingAnchor: Anchor = {
  key: "bizOutsourcing",
  name: "외주 개발",
  href: "https://sparta-builders.com/",
  brand: "외주 개발",
  isSub: true,
};

export const bizRecruitAnchor: Anchor = {
  key: "bizRecruit",
  name: "신입 개발자 채용",
  href: "https://intellipick.spartacodingclub.kr/company",
  brand: "신입 개발자 채용",
  isSub: true,
};

export const voucherAnchor: Anchor = {
  key: "voucher",
  name: "수강권",
  href: `${process.env.ONLINE_URL}/mypage/myvoucher`,
  isLoginNeededToNavigate: true,
};

export const registrationStatusAnchor: Anchor = {
  key: "registrationStatus",
  name: "국비 신청 내역",
  href: `/kdc/registration_status`,
  isLoginNeededToNavigate: true,
};

export const classroomAnchor: Anchor = {
  key: "classroom",
  name: "내 강의실",
  href: `${process.env.ACADEMIA_URL}/classroom`,
};

export const homeworkFeedbackAnchor = {
  key: "homework",
  name: "숙제 피드백",
  href: `${process.env.ONLINE_URL}/homework/feedback`,
};

export const certificateAnchor: Anchor = {
  key: "certificate",
  name: "수강증/수료증",
  href: `${process.env.ONLINE_URL}/mypage/mycertificate`,
};

export const basicInfoAnchor: Anchor = {
  key: "basicInfo",
  name: "계정",
  href: `${process.env.ONLINE_URL}/mypage/mybasicinfo`,
};

export const logoutAnchor: Anchor = {
  key: "logout",
  name: "로그아웃",
  onClick: logout,
};

export const bizDropdownList = [
  bizEducationAnchor,
  bizOutsourcingAnchor,
  bizRecruitAnchor,
];
export const topAnchors = [];

export const topRightAnchors = [faqAnchor, BizAnchor];

export const mainAnchor = [
  catalogAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileMainAnchor = [
  catalogAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileBottomAnchors = [intellipickMobileAnchor];

export const mobileMyPageAnchors = [
  classroomAnchor,
  registrationStatusAnchor,
  voucherAnchor,
  certificateAnchor,
  homeworkFeedbackAnchor,
  basicInfoAnchor,
];

export const customGnbV3 = {
  nb: {
    pageName: "국비지원",
  },
  event: {
    pageName: "이벤트",
  },
  exhibition: {
    pageName: "수강생 작품",
  },
  community: {
    pageName: "커뮤니티",
  },
  blog: {
    pageName: "블로그",
  },
  catalog: {
    pageName: "전체 강의",
  },
};
