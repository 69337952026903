import { useCardColumnCount } from "src/businesslogics/catalog/getCardColumnCount";
import { RecommendCards } from "src/components/molecules/home/v4/RecommendCards/RecommendCards";
import {
  ProductCardsType,
  ProductRecommendType,
} from "src/models/catalog/business/catalogBusiness";
import { CourseCardType } from "src/models/home/homeV4.models";
import { useCustomRemoteConfig } from "../../../../../businesslogics/_common/hackle/useCustomRemoteConfig";

// 아카데미아 강의 추천 카드
export const ProductRecommend = () => {
  const remoteConfig: ProductRecommendType = useCustomRemoteConfig(
    "scc_main_recommend_course_cards_v2",
    "json"
  );

  const cardColumnCount = useCardColumnCount();

  const courseCards = makeCourseCardType(remoteConfig?.productCards);
  return (
    <RecommendCards
      title={remoteConfig.title}
      description={remoteConfig.description}
      cards={courseCards}
      columnCount={cardColumnCount}
    />
  );
};

const makeCourseCardType = (products: ProductCardsType[]): CourseCardType[] => {
  return products.map((product) => ({
    courseId: product.productId,
    keyword: product.thumbnailUrl,
    title: product.title,
    description: product.description,
    price: product.price === "0" ? "무료" : product.price,
    url: product.url,
  }));
};
