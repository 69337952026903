import { useEffect, useRef } from "react";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const usePageView = (courseData: any) => {
  const hasSentLog = useRef(false);

  useEffect(() => {
    if (!hasSentLog.current && courseData.enrollingCourse) {
      sendCPLog("scc_studyGuidePage_view", {
        course_id: courseData.enrollingCourse.kdcCourseId,
        course_title: courseData.enrollingCourse.hrdNetTitle,
        roundId: courseData.enrollingCourse.class_agent_pk,
      });
      hasSentLog.current = true; // Set the flag to true after sending the log
    }
  }, [courseData.enrollingCourse]);
};

export const useClickEvent = (courseData: any) => {
  return (buttonText: string) => {
    sendCPLog("scc_studyGuidePage_click_button", {
      course_id: courseData.enrollingCourse.kdcCourseId,
      course_title: courseData.enrollingCourse.hrdNetTitle,
      roundId: courseData.enrollingCourse.class_agent_pk,
      button_text: buttonText,
    });
  };
};

export const courseOopyMap = {
  "65d2b38b1076f1d4cc075361": `${process.env.BASE_URL}/kdc/study/sql_study`,
  "65d2a90040ddbb5b4661f7b0": `${process.env.BASE_URL}/kdc/study/ga4_study`,
  "65d2acd2a645a72c5e4a08ad": `${process.env.BASE_URL}/kdc/study/appBuild_study`,
};
