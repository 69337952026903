"use client";

import { useLoadableFeatureDetail } from "@hackler/react-sdk";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { academiaFFAtom } from "../../academia/flag.store";
import { getDeviceId } from "../utils/uniqueId";
import { getCookie } from "cookies-next";

export function FeatureFlagInitializer({ featureKey }: { featureKey: number }) {
  // 여러 featureKey 를 atom 으로 관리하려면 추가 개발 필요
  const setFeatureFlag = useSetAtom(academiaFFAtom);
  const {
    isLoading,
    decision: { isOn, reason },
  } = useLoadableFeatureDetail(featureKey);

  useEffect(() => {
    console.debug("FF:", {
      isOn,
      reason,
      deviceId: getDeviceId(),
      userId: getCookie("user_id"),
    });
    setFeatureFlag({ isLoading, isOn, reason });
  }, [isLoading, isOn, setFeatureFlag]);

  return null;
}
