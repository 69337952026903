import { useAtom, useAtomValue } from "jotai";
import {
  isLoginModalShowAtom,
  nextPageAfterLoginAtom,
} from "../../../stores/_common/modal";
import { useEffect, useState } from "react";
import { useFetchIsLoggedIn } from "@teamsparta/auth-frontend";
import { useRouter } from "next/router";
import { getCookie } from "../window/cookie";
import { consoleLogForDev } from "../utils/consoleLog";

const addDomainToUrl = (url) => {
  if (!url.includes("://")) {
    url = process.env.BASE_URL + url;
  }
  return url;
};

const navigateToPage = (nextPage?: string) => {
  if (nextPage && typeof window !== "undefined") {
    window.location.href = addDomainToUrl(nextPage);
  }
};

export const useIsLoggedInV2 = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const query = useFetchIsLoggedIn();
  const { isLoading, isFetching, isSuccess, data } = query;
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);

  useEffect(() => {
    setIsAuthenticated(isLoading || (isSuccess && data?.is_logined));
  }, [isLoading, isFetching, isSuccess, data?.is_logined, isLoginModalShow]);

  return isAuthenticated;
};
export const currentHref = () => {
  if (typeof window === "undefined") return null;
  return window.location.href;
};

export const currentPath = () => {
  if (typeof window === "undefined") return null;
  return window.location.pathname + window.location.search;
};

export const useWithLoginV2 = () => {
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [nextPageAfterLogin, setNextPageAfterLogin] = useAtom(
    nextPageAfterLoginAtom,
  );
  const isURIEncoded = (str) => {
    try {
      const decoded = decodeURIComponent(str);
      return decoded !== str && encodeURIComponent(decoded) === str;
    } catch (e) {
      return false;
    }
  };

  return (nextPage?: string) => {
    nextPage =
      !!nextPage && !isURIEncoded(nextPage)
        ? encodeURIComponent(nextPage)
        : null;

    setNextPageAfterLogin(nextPage);
    setIsLoginModalShow(true);
  };
};

export const usePlainLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const currentAsNext = currentPath();

  return () => {
    withLoginV2(currentAsNext);
  };
};

export const useExecuteWithLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const isLoggedIn = useIsLoggedInV2();
  const currentAsNext = currentPath();

  return (
    callbackWithLogin: Function,
    callbackBeforeLoginV1?: Function,
    customNext?: string,
  ) => {
    if (isLoggedIn) {
      callbackWithLogin();
      return;
    }

    withLoginV2(customNext ?? currentAsNext);
  };
};

export const useNavigateWithLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const isLoggedIn = useIsLoggedInV2();

  return (nextPage?: string) => {
    if (isLoggedIn) {
      navigateToPage(nextPage);
      return;
    }

    withLoginV2(nextPage);
    return;
  };
};

interface UseGetPageWithLoginV2Props {
  condition: boolean;
  pathAfterLoginAborted: string;
}

export const useGetPageWithLoginV2 = ({
  condition,
  pathAfterLoginAborted = "/",
}: UseGetPageWithLoginV2Props) => {
  const { isLoading, data: isLoggedInData } = useFetchIsLoggedIn();
  const withLoginV2 = useWithLoginV2();
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [hasLoginModalPopped, setHasLoginModalPopped] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (hasLoginModalPopped && !isLoginModalShow) {
      //하단 if문은 로그인 모달이 닫혔을 때 실행됩니다.
      if (typeof window !== "undefined" && window.history.length > 1) {
        router.push(pathAfterLoginAborted);
      }
    }
  }, [hasLoginModalPopped, isLoginModalShow]);
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (previousPath !== url) {
        // 알 수 없는 이유로 이 시점에 isLoginModalShow 는 false 임.
        // 사실은 true 일때 setFalse 하는 것이 맞음
        if (!isLoggedInData?.is_logined && !isLoginModalShow) {
          setIsLoginModalShow(false);
        }
      }
      // 현재 경로를 직전 경로로 업데이트
      setPreviousPath(url);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [previousPath, router]);

  useEffect(() => {
    if (isLoading) return;

    if (
      condition &&
      !isLoggedInData?.is_logined &&
      typeof document !== "undefined" &&
      !getCookie("token")
    ) {
      withLoginV2(currentPath());
      setHasLoginModalPopped(true);
    }
  }, [isLoggedInData?.is_logined, isLoading]);
};
