import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { IN_HOUSE_URL } from "../../models/_common/urls";
import { useUserCouponCountQuery, useUserMetaQuery } from "../../queries/gnb";
import { applyEnvUrl } from "../_common/utils/applyEnvUrl";
import { HomeV4 } from "../../components/organisms/home/v4";
import { useAtomValue } from "jotai";
import { academiaFFAtom } from "./flag.store";

export const useCouponCountText = () => {
  const [text, setText] = useState("로딩중");
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  const userCouponCount = useUserCouponCountQuery();
  const userMetaData = useUserMetaQuery();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isOn && !userCouponCount.isLoading) {
      setText(getCouponCountText(userCouponCount.data));
    }
    if (!isOn && !userMetaData.isLoading) {
      setText(userMetaData.data?.coupon);
    }
  }, [isLoading, isOn, userCouponCount.isLoading, userMetaData.isLoading]);

  return text;
};

const getCouponCountText = (count) => {
  if (count === undefined) {
    return "로딩중";
  }

  if (count > 0) {
    return `${count}장`;
  }
  return "없음";
};

export const useGetUrl = (page: "catalog" | "classroom") => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  if (isLoading) {
    return undefined;
  }

  return {
    ["catalog-new"]: IN_HOUSE_URL.상품탐색,
    ["catalog-old"]: IN_HOUSE_URL.수업탐색,
    ["classroom-new"]: IN_HOUSE_URL.신_내강의실,
    ["classroom-old"]: IN_HOUSE_URL.구_내강의실,
  }[`${page}-${isOn ? "new" : "old"}`];
};

export const useNavigateTo = (page: "catalog" | "classroom") => {
  const { push } = useRouter();
  const url = useGetUrl(page);

  if (!url) {
    return () => {};
  }
  return () => push(url);
};

const CATEGORY_MAP: Record<string, string> = {
  국비지원: "kdc",
  개발: "development",
  "AI ∙ GPT": "aigpt",
  데이터: "datascience",
  "취업 ∙ 자격증": "careercertificate",
  디자인: "design",
};

const REVERSE_CATEGORY_MAP = Object.fromEntries(
  Object.entries(CATEGORY_MAP).map(([key, value]) => [value, key])
);

export const useCatalogUrlConverter = () => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  return useCallback(
    (url: string) => {
      if (!isOn) return url;
      if (!url.includes("/catalog")) return url;
      if (isLoading) return url;

      url = applyEnvUrl(url);
      if (!url.includes("://")) {
        url = `${process.env.BASE_URL}${url}`;
      }

      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split("/").filter(Boolean);
      const isNewVersionUrl =
        pathSegments.length === 2 && pathSegments[0] === "catalog";
      const searchParams = new URLSearchParams(urlObject.search);
      //50만 수강생 ~~ 무료 강의 예외 처리
      const selectedKeyword = searchParams.get("selectedKeyword");
      const filterOption = searchParams.get("filter_option");
      const selectedMinor = searchParams.get("selectedminor");

      if (
        filterOption === "무료" &&
        selectedMinor === "전체" &&
        selectedKeyword === "전체"
      ) {
        return `${process.env.BASE_URL}/catalog/scc?category=development&sub-category=free`;
      }
      if (selectedKeyword === "전체") {
        return `${process.env.BASE_URL}/catalog/scc?category=kdc&sub-category=`;
      }

      if (isOn) {
        if (isNewVersionUrl) return url;

        const newVersionUrl = new URL(`${process.env.BASE_URL}/catalog/scc`);
        const selectedKeyword = searchParams.get("selectedKeyword");
        if (selectedKeyword) {
          const newCategory = CATEGORY_MAP[selectedKeyword];
          if (newCategory) {
            newVersionUrl.searchParams.set("category", newCategory);
          }
        }
        return newVersionUrl.toString();
      }

      if (!isNewVersionUrl) return url;

      const oldVersionUrl = new URL(`${process.env.BASE_URL}/catalog`);
      const category = searchParams.get("category");
      if (category) {
        const oldKeywords = REVERSE_CATEGORY_MAP[category];
        if (oldKeywords) {
          oldVersionUrl.searchParams.set("selectedKeyword", oldKeywords);
        }
      }
      return oldVersionUrl.toString();
    },
    [isLoading, isOn]
  );
};

export const useHomeRecommend = () => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  return !isLoading && isOn ? HomeV4.Product : HomeV4.Course;
};

const ONLINE_MAP: Record<string, string> = {
  "online/web_basic": "product/46",
  "online/gpt_300_online": "product/34",
  "online/data_v2": "product/49",
  "online/app": "product/57",
};

export const useOnlineUrlConverter = () => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  return useCallback(
    (url: string) => {
      if (!url.includes("/online")) return url; // online URL이 아니면 그대로 반환
      if (isLoading) return url; // 로딩 중이면 원본 URL 반환

      url = applyEnvUrl(url);
      if (!url.includes("://")) {
        url = `${process.env.BASE_URL}${url}`;
      }

      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split("/").filter(Boolean);
      const pathKey = pathSegments.join("/"); // 경로 키 생성

      if (ONLINE_MAP[pathKey]) {
        if (!isOn) return url;

        return `${process.env.BASE_URL}/${ONLINE_MAP[pathKey]}`;
      }

      return url;
    },
    [isLoading, isOn]
  );
};
