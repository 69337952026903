import { sendCPLog } from '@teamsparta/cross-platform-logger';
import { cloneElement, MouseEvent, type ReactElement } from 'react';

/**
 * LogClick 컴포넌트의 Props 타입 정의
 * @typedef {Object} LogClickProps
 * @property {ReactElement} children - 클릭 이벤트를 로깅할 단일 자식 컴포넌트
 * @property {string} logName - 로깅 이벤트의 이름
 * @property {Record<string, unknown>} [data] - 로깅할 추가 데이터
 * @property {boolean} [disabled] - 로깅 비활성화 여부
 */
type LogClickProps = {
  children: ReactElement;
  logName: string;
  data?: Record<string | number | symbol, unknown>;
  disabled?: boolean;
  isGtm?: boolean;
};

/**
 * LogClick은 자식 컴포넌트의 클릭 이벤트를 로깅하는 컴포넌트입니다.
 * 자식 컴포넌트의 기존 onClick 이벤트 핸들러를 보존하면서 로깅 기능을 추가합니다.
 *
 * @component
 * @example
 * ```tsx
 * <LogClick
 *   logName="버튼_클릭"
 *   data={{ buttonId: 'submit-button' }}
 * >
 *   <button onClick={() => console.log('clicked')}>
 *     제출하기
 *   </button>
 * </LogClick>
 * ```
 *
 * @param {LogClickProps} props - 컴포넌트 props
 * @param {ReactElement} props.children - 클릭 이벤트를 로깅할 단일 자식 컴포넌트
 * @param {string} props.logName - 로깅 이벤트의 이름
 * @param {Record<string, unknown>} [props.data] - 로깅할 추가 데이터 (선택사항)
 * @param {boolean} [props.disabled=false] - 로깅 비활성화 여부 (선택사항, 기본값: false)
 * @returns {ReactElement} 로깅이 추가된 클론된 자식 컴포넌트
 */
export const LogClick = ({
  children,
  logName,
  data,
  disabled = false,
  isGtm = false,
}: LogClickProps) => {
  return cloneElement(children, {
    onClick: (event: MouseEvent<HTMLElement>) => {
      if (!disabled) {
        sendCPLog(logName, data, isGtm);
      }

      children?.props?.onClick?.(event);
    },
  });
};
