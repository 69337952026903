import { ActionPlus } from "@teamsparta/design-system";
import * as S from "./spartaStartToEnd.style";
import { StartToEndCardData } from "src/models/home/homeV4.models";
import { useRouter } from "next/router";
import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import { sendLogV2 } from "src/businesslogics/_common/loggingV2";
import { useCatalogUrlConverter } from "../../../../../businesslogics/academia/useFeatureFlag";

export const SpartaStartToEnd = () => {
  const router = useRouter();
  const convertCatalogUrl = useCatalogUrlConverter();

  const onClickCard = (url, title) => {
    sendLogV2("product_click", router.pathname, {
      product_title: title,
    });
    router.push(convertCatalogUrl(url));
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>50만 수강생이 선택한</S.Title>
        <S.ColorTitle>스파르타코딩클럽</S.ColorTitle>
      </S.TitleContainer>
      <S.CardContainer>
        {StartToEndCardData.map((card, index) => (
          <S.Card
            key={`startToEnd-${card.title}-${index}`}
            onClick={() => {
              onClickCard(card.url, card.title);
            }}
          >
            <S.CardTitleContainer>
              <S.CardTagBagde badgeColor={card.badgeColor}>
                <S.CardTag>{card.tagName}</S.CardTag>
              </S.CardTagBagde>
              <S.CardTitle>{card.title}</S.CardTitle>
              <S.CardDesc>{card.description}</S.CardDesc>
            </S.CardTitleContainer>
            <ImageWrapperWithFallback>
              <S.CardImage
                src={`/assets/images/home/v4/start_to_end_${index}.webp`}
                loading="lazy"
              />
            </ImageWrapperWithFallback>
            <S.Cta>
              <ActionPlus size={16} color={"#3A3E41"} />
            </S.Cta>
          </S.Card>
        ))}
      </S.CardContainer>
    </S.Container>
  );
};
