import * as S from "./study.style";
import { ArrowChevronLeft } from "@teamsparta/design-system";
import { courseOopyMap } from "../../../organisms/kdc/studyPayment/log";
import { useRouter } from "next/router";

export const StudyPaymentGnb = () => {
  const router = useRouter();
  const query = router.query;
  const courseId = query?.course_ids as string;
  const referralLink = courseOopyMap[courseId];
  return (
    <S.StudyGnbWrapper>
      <S.StudyPaymentGnbContainer>
        <S.GnbLeft
          onClick={() => {
            router.push(referralLink ?? "/");
          }}
        >
          <ArrowChevronLeft size={20} />
        </S.GnbLeft>
        <S.GnbTitle>스터디 신청</S.GnbTitle>
        <S.GnbRight />
      </S.StudyPaymentGnbContainer>
    </S.StudyGnbWrapper>
  );
};
