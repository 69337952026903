import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { showLineBannerAtom } from "src/stores/showLineBannerAtom";
import {
  gnbMobileHeight,
  gnbTabletHeight,
  gnbTopMenuHeight,
  lineBannerMobileHeight,
  lineBannerTabletHeight,
} from "src/styles/variables";
import { customGnbV3 } from "../../../components/templates/gnbV3/models/gnbV3.models";
import { LnbModel, LnbPages } from "../../../models/_common/lnb.model";
import { CustomGnbAction } from "../../../models/_layout/gnbV2.models";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../stores/_layout/gnb";
import { currentBlogCategoryAtom } from "../../../stores/community/content";
import { useUserId } from "../../_common/auth";
import { isSectionMovingAtom } from "../../_common/lnb";
import { logGnbClick } from "../../_common/loggingV2";
import { useNavigateWithLoginV2 } from "../../_common/login";
import { useGetUrl, useNavigateTo } from "../../academia/useFeatureFlag";
import { useIsGnbMobile, useWindowScrollY } from "../window";

export const hasRightArrow = (anchor) => anchor.key !== "logout";

export const getUrlSplitted = (url) => {
  return url
    .split("?")[0]
    .split(/(?=\/)/g)
    .map((path) => path.replace("/", ""));
};

export const goLogOut = () => {
  const next = encodeURIComponent(window.location.href);
  window.location.href = `${process.env.ONLINE_URL}/logout?next=${next}`;
};

export const useMovePage = () => {
  const router = useRouter();
  const [, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  useEffect(() => {
    setIsNavigatorOpen(false);
    setIsMyPageOpen(false);
  }, [router.asPath]);
};

export const useIsMain = () => {
  const [isMain, setIsMain] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      setIsMain(router.pathname === "/");
    }
  }, [router.isReady, router.pathname]);
  return isMain;
};

export const useIsTargetPage = (target, isAbsoluteMatch = false) => {
  const [isMatch, setIsMatch] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (!target?.includes("://")) {
      // TODO: 언젠간 하겠지.. 리팩토링
      if (router.isReady) {
        if (isAbsoluteMatch) {
          setIsMatch(router.asPath.split("?")[0] === target);
        } else if (
          target?.split("/").filter((part) => !!part.length).length === 1
        ) {
          setIsMatch(router.asPath.split(/(?=\/)/g)[0] === target);
        } else {
          setIsMatch(router.asPath.includes(target));
        }
      }
    }
  }, [router.isReady, router.pathname]);
  return isMatch;
};

export const useIsTargetBlogCategory = (target) => {
  const [isMatch, setIsMatch] = useState(false);
  const router = useRouter();
  const [currentBlogCategory] = useAtom(currentBlogCategoryAtom);
  useEffect(() => {
    if (router.isReady) {
      // category 포함될 때도 있고, 아닐 때도 있고, 모든 케이스를 정확하게 처리하기 위한 노력
      setIsMatch(
        target
          ?.replace("/blog", "")
          .replace("/category", "")
          .split("?")[0]
          .replace("/", "") === currentBlogCategory,
      );
    }
  }, [router.isReady, router.pathname, currentBlogCategory]);
  return isMatch;
};

export const useCustomGnb = () => {
  const [customGnbModel, setCustomGnbModel] = useState(null);
  const router = useRouter();
  const customGnbObj = customGnbV3;
  useEffect(() => {
    if (router.isReady) {
      setCustomGnbModel(null);
      const target = router.pathname
        .split("/")
        .filter((part) => !!part.length)[0];
      if (!!Object.keys(customGnbObj).filter((key) => key === target).length) {
        const gnbModel = { ...customGnbObj[target] };
        gnbModel.left &&
          (gnbModel.left.onClick = _getCustomGnbAction(
            gnbModel.left.action,
            router,
          ));
        gnbModel.right &&
          (gnbModel.right.onClick = _getCustomGnbAction(
            gnbModel.right.action,
            router,
          ));
        setCustomGnbModel(gnbModel);
      }
    }
  }, [router.isReady, router.asPath]);
  return customGnbModel;
};

export const _getCustomGnbAction = (
  action: CustomGnbAction,
  router: any = null,
) => {
  switch (action) {
    case CustomGnbAction.BACK:
      return () => {
        router?.back();
      };
    case CustomGnbAction.COURSE_SEARCH:
      return () => {
        router?.push("/catalog/search");
      };
    default:
      console.log("should not be reached");
  }
};

export const useHasLnb = () => {
  const [hasLnb, setHasLnb] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      const pathArray = getUrlSplitted(router.asPath);
      if (pathArray[0] === "nb" && pathArray[1]) {
        setHasLnb(false);
      } else {
        setHasLnb(Object.keys(LnbPages).includes(pathArray[0]));
      }
    }
  }, [router.isReady, router.asPath]);
  return hasLnb;
};
export const useLnb = () => {
  const [lnbModel, setLnbModel] = useState<LnbModel>(null);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      const pathArray = getUrlSplitted(router.asPath);
      if (pathArray[0] === "nb") {
        setLnbModel(null);
      } else {
        setLnbModel(LnbPages[pathArray[0]]);
      }
    }
  }, [router.isReady, router.asPath]);
  return lnbModel;
};

export const useGetUrlParam = (key) => {
  const router = useRouter();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (router.isReady) {
      setValue(router.query[key]);
    }
  }, [router.isReady, router.query, key]);

  return value;
};

export const useLayoutPaddingTop = () => {
  const isMobile = useIsGnbMobile();
  const isGnbFixed = useFixedPosition();
  const hasLnb = useHasLnb();
  const [gap, setGap] = useState(0);
  const [showLineBanner] = useAtom(showLineBannerAtom);

  useEffect(() => {
    let plusGap = 0;
    // if (isGnbFixed) {
    plusGap = isMobile ? gnbMobileHeight : gnbTabletHeight + gnbTopMenuHeight;
    // if (hasLnb) {
    //   plusGap += isMobile ? lnbTabletHeight : lnbMobileHeight;
    // }
    if (showLineBanner) {
      plusGap += isMobile ? lineBannerMobileHeight : lineBannerTabletHeight;
    }
    // }
    setGap(plusGap);
  }, [isGnbFixed, hasLnb, isMobile, showLineBanner]);

  return gap;
};

export const useGoMain = () => {
  const router = useRouter();
  return () => router.push("/");
};

export const useClickAnchor = () => {
  const userId = useUserId();
  const router = useRouter();
  const [_, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const [__, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const navigateWithLogin = useNavigateWithLoginV2();
  const navigateToCatalog = useNavigateTo("catalog");
  const classroomUrl = useGetUrl("classroom");

  return (anchor) => {
    logGnbClick(anchor.key, {
      brand: anchor?.brand ?? "",
      page_url: `${process.env.BASE_URL}${router.asPath}`,
      page_title: document.title,
      button_text: anchor.name,
      button_href: anchor.href,
    });

    if (anchor.isLoginNeededToNavigate) {
      navigateWithLogin(anchor.href);
    } else if (anchor.key === "catalogv3") {
      navigateToCatalog();
    } else if (anchor.key === "classroom") {
      navigateWithLogin(classroomUrl);
    } else if (anchor.key === "homework") {
      router.push(anchor.href + `/${userId}`);
    } else if (anchor.href) {
      // outlink 인 경우에만 새창에서 띄우고, scc 도메인인 경우 router 로 이동
      if (
        anchor.href.includes("://") &&
        !anchor.href.includes(process.env.ONLINE_URL)
      ) {
        window.open(anchor.href);
      } else {
        router.push(anchor.href);
        setIsMyPageOpen(false);
        setIsNavigatorOpen(false);
      }
    } else if (!!anchor.onClick) {
      anchor.onClick();
    }
  };
};

export const useFixedPosition = () => {
  const [fixedPosition, setIsFixedPosition] = useState(false);
  const scrollY = useWindowScrollY();
  const lastScroll = useRef(0);
  const [isMovingSection, setIsMovingSection] = useAtom(isSectionMovingAtom);
  useEffect(() => {
    setIsMovingSection(false);
  }, []);

  const timer = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        timer.current = null;
      }, 100);

      if (scrollY === 0) {
        setIsFixedPosition(true);
      } else if (scrollY < lastScroll.current) {
        setIsFixedPosition(true);
      } else if (!isMovingSection) {
        setIsFixedPosition(false);
      }
    }
    lastScroll.current = scrollY;
  }, [scrollY, isMovingSection]);
  return fixedPosition;
};

/**
 * 구조 개편에 따른 Gnb 동작 방식 대응
 * 1. 스크롤 상단에서 Gnb가 사라지지 않도록 하기 위해 scrollY 5로 여유 값 추가
 * 2. 페이지 이동 시 Gnb가 고정된 상태로 자연스럽게 나타나도록 초기 상태 관리
 **/
export const useFixedPositionForGnb = () => {
  const [fixedPosition, setIsFixedPosition] = useState(false);
  const scrollY = useWindowScrollY();
  const router = useRouter();
  const lastScroll = useRef(0);
  const [isMovingSection, setIsMovingSection] = useAtom(isSectionMovingAtom);
  const timer = useRef(null);

  useEffect(() => {
    // 페이지 이동 시 초기화
    lastScroll.current = 0;
    setIsFixedPosition(true);

    setIsMovingSection(false);
  }, [router.asPath]);

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  // 스크롤에 따른 fixed 상태 업데이트
  useEffect(() => {
    // 페이지 초기 상태일 때 바로 고정 상태로 설정
    if (lastScroll.current === 0) {
      setIsFixedPosition(true);
      lastScroll.current = scrollY;
      return;
    }

    if (!timer.current) {
      timer.current = setTimeout(() => {
        timer.current = null;
      }, 100);

      if (scrollY <= 5 || scrollY < lastScroll.current) {
        setIsFixedPosition(true);
      } else if (!isMovingSection) {
        setIsFixedPosition(false);
      }
    }
    lastScroll.current = scrollY;
  }, [scrollY, isMovingSection]);

  return fixedPosition;
};
