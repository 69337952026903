import { useCallback, useEffect, useRef } from "react";

export const useOnOutsideClick = (
  ref: React.RefObject<any>,
  callback: () => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      } else {
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
};

export const useClickDynamicOutside = (ref, callback) => {
  const observer = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
        return;
      }
    },
    [ref, callback]
  );

  const observeChanges = useCallback(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new MutationObserver(() => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("mousedown", handleClickOutside);
    });

    if (ref.current) {
      observer.current.observe(ref.current, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });
    }
  }, [handleClickOutside]);

  useEffect(() => {
    observeChanges();
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, observeChanges]);
};
