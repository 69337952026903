import {
  KDC_MINOR_CATEGORY,
  KdcCategoryType,
} from "../nb/category/nbCategory.models";

const sqlStudyFaq = {
  "질문 1": {
    data: [
      {
        답변: [
          "네, 본 스파르타 스터디는 국비지원 강의 수강과 함께 진행되어 내일배움카드로 강의를 구매해주셔야 합니다.<br/>내일배움카드 신규 발급이 필요하다면, 신한 앱카드로 신청하시면 1-3일 안에 발급받을 수 있습니다.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 꼭 내일배움카드로 강의를 결제해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 2": {
    data: [
      {
        답변: [
          "아쉽지만 본 스터디는 [엑셀보다 쉽고 빠른 SQL] 강의를 최초로 수강하시는 수강생분들만 참여가 가능합니다.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 이미 SQL 강의 수강 내역이 있는 사람도 스터디에 참여할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 3": {
    data: [
      {
        답변: [
          "본 SQL 스터디는 3주간 강의를 수강하고 실무 활용법을 학습하는 집중 관리형 스터디입니다.<br/><br/>스터디 신청 없이 국비지원 강의만 구매할 경우, 참여 수강생들과 함께 커뮤니티(네이버 밴드)에서 진행되는 수강 인증 미션과 현직 튜터의 실시간 세션에 참여할 수 없어요.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 그냥 강의를 구매하는 것과 스터디는 무엇이 다른가요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 4": {
    data: [
      {
        답변: [
          "필수는 아니지만, SQL 실무 활용에 대한 이해도를 높일 수 있는 특강 세션이니 참여하시는 것을 추천드립니다.",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 실시간 세션은 꼭 필수로 참여해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 5": {
    data: [
      {
        답변: [
          "미션 달성 조건인 강의 수강인증 3회 & 세션 참석 1회 중, 피치 못한 사정이 생겼을 때 딱 한 번 실패를 면제할 수 있는 '면제권 1회'를 제공해드립니다. 조건 미충족 시 미션 혜택(네이버 페이 포인트 3만 원)을 받을 수 없습니다.<br/><br/>스파르타 스터디에서 계획한 타임라인대로 따라오셔야 단기간 강의 완강이 가능하니, 출석 인증을 성실하게 따라와 주세요!",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 사정이 생겨 출석 인증을 못하면 어떻게 되나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 6": {
    data: [
      {
        답변: [
          "스터디 종료 후에도 커뮤니티를 통해 다양한 정보와 이벤트 소식을 전해 드릴 예정이니 계속 사용하셔도 괜찮습니다.",
        ],
        "카테고리 | 질문": [
          "스터디 종료 후 | 스터디에서 사용한 커뮤니티는 계속 사용할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 7": {
    data: [
      {
        답변: [
          "강의를 80% 이상 수강하면, 강의 기본 수강기간 (5주차 기준) 종료 다음날부터 스파르타코딩클럽 [마이페이지] > [수강증/수료증] 에뉴에서 발급 가능합니다.",
        ],
        "카테고리 | 질문": [
          "스터디 종료 후 | 수료증은 어디서 발급 받을 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
};

const ga4StudyFaq = {
  "질문 1": {
    data: [
      {
        답변: [
          "네, 본 스파르타 스터디는 국비지원 강의 수강과 함께 진행되어 내일배움카드로 강의를 구매해주셔야 합니다.<br/>내일배움카드 신규 발급이 필요하다면, 신한 앱카드로 신청하시면 1-3일 안에 발급받을 수 있습니다.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 꼭 내일배움카드로 강의를 결제해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 2": {
    data: [
      {
        답변: [
          "아쉽지만 본 스터디는 [GA4와 SQL로 데이터 마케팅 한번에 끝내기] 수강 기간과 동일한 회차의 강의를 구매한 고객 대상으로 진해됩니다.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 이미 GA4 강의 수강 내역이 있는 사람도 스터디에 참여할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 3": {
    data: [
      {
        답변: [
          "본 GA4 스터디는 3주간 강의를 수강하고 실무 활용법을 학습하는 집중 관리형 스터디입니다.<br/><br/>스터디 신청 없이 국비지원 강의만 구매할 경우, 참여 수강생들과 함께 커뮤니티(네이버 밴드)에서 진행되는 수강 인증 미션과 현직 튜터의 실시간 세션에 참여할 수 없어요.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 그냥 강의를 구매하는 것과 스터디는 무엇이 다른가요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 4": {
    data: [
      {
        답변: [
          "필수는 아니지만, 실제 GA4 전문가 분이 실무 활용 꿀팁을 공유해 주시는 시간이니 참여하시는 것을 추천드립니다.",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 실시간 세션은 꼭 필수로 참여해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 5": {
    data: [
      {
        답변: [
          "미션 달성 조건인 강의 수강인증 3회 & 세션 참석 1회 중, 피치 못한 사정이 생겼을 때 딱 한 번 실패를 면제할 수 있는 '면제권 1회'를 제공해드립니다. 조건 미충족 시 미션 혜택(네이버 페이 포인트 3만 원)을 받을 수 없습니다.<br/><br/>스파르타 스터디에서 계획한 타임라인대로 따라오셔야 단기간 강의 완강이 가능하니, 출석 인증을 성실하게 따라와 주세요!",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 사정이 생겨 출석 인증을 못하면 어떻게 되나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 6": {
    data: [
      {
        답변: [
          "스터디 종료 후에도 커뮤니티를 통해 다양한 정보와 이벤트 소식을 전해 드릴 예정이니 계속 사용하셔도 괜찮습니다.",
        ],
        "카테고리 | 질문": [
          "스터디 종료 후 | 스터디에서 사용한 커뮤니티는 계속 사용할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 7": {
    data: [
      {
        답변: [
          "강의를 80% 이상 수강하면, 강의 기본 수강기간 (5주차 기준) 종료 다음날부터 스파르타코딩클럽 [마이페이지] > [수강증/수료증] 에뉴에서 발급 가능합니다.",
        ],
        "카테고리 | 질문": [
          "스터디 종료 후 | 수료증은 어디서 발급 받을 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
};

const appBuildStudyFaq = {
  "질문 1": {
    data: [
      {
        답변: [
          "네, 본 스파르타 스터디는 국비지원 강의 수강과 함께 진행되어 <b>내일배움카드로 강의를 구매해야</b>합니다.<br/>스터디 신청 시, 카드발급 안내를 자세히 드릴테니 걱정하지 마세요!",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 꼭 내일배움카드로 강의를 결제해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 2": {
    data: [
      {
        답변: [
          "이번 스터디는 <b>‘노코드로 수익형 AI 앱 만들기’ 기존 강의를 수강하지 않으신 분들 대상</b>으로 진행됩니다.",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 이미 강의를 수강한 적 있는 사람도 스터디에 참여할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 3": {
    data: [
      {
        답변: [
          "스파르타 스터디의 목표는 “혼자 공부했을 때 <b>꺾이는 의지를 함께 잡고 끝까지 완강하는 것”</b>입니다.<br/><br/>분명 처음 수강할 때 불타올랐던 의지는 시간이 갈 수록 줄어들기 마련이라, 스터디에서는 어려움이 있을 때는 매니저, 튜터에게 도움을 받으며 완강을 하게 됩니다.<br/><br/>본 수익형 앱개발 스터디는 딱 3주간 강의 완강을 목표로 하는 <b>집중 관리형 스터디</b>입니다. ",
        ],
        "카테고리 | 질문": [
          "스터디 신청 전 | 그냥 강의를 구매하는 것과 스터디는 무엇이 다른가요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 4": {
    data: [
      {
        답변: [
          "네, 스터디 미션 중 필수 참여입니다.<br/>실제 FlutterFlow 앱개발 서비스를 10개 이상 개발한 경력이 있는 15년차 풀스택 전문 개발자분이 앱 기획 및 제작부터 배포까지의 전과정에서 알아야 할 것들과 수익화 꿀팁까지 전수해드리는 시간이라 꼭 들어보시는 것을 추천드립니다.",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 특강 세션은 꼭 필수로 참여해야 하나요?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 5": {
    data: [
      {
        답변: [
          "출석 인증 미션 진행중 피치 못한 사정이 생겼을 때 딱 한 번 실패를 면제할 수 있는 ‘면제권 1회’를 제공해 드립니다.<br/><br/>스파르타 스터디에서 짜드리는 타임라인대로 따라오셔야 단기간 강의 완강이 가능하니, 출석 인증을 성실하게 따라와주세요! ",
        ],
        "카테고리 | 질문": [
          "스터디 진행 중 | 사정이 생겨 출석 인증을 깜빡하고 못했는데 어떡하죠?",
        ],
      },
    ],
    is_show: true,
  },
  "질문 6": {
    data: [
      {
        답변: [
          "스터디 종료 후에도 커뮤니티를 통해 다양한 정보와 이벤트 소식을 전해 드릴 예정이니 계속 사용하셔도 괜찮습니다.",
        ],
        "카테고리 | 질문": [
          "스터디 종료 후 | 스터디에서 사용한 커뮤니티는 계속 사용할 수 있나요?",
        ],
      },
    ],
    is_show: true,
  },
};

const ga4StudyImages = [
  "/assets/images/kdc/study/ga4_study/img-div-01.webp",
  "/assets/images/kdc/study/ga4_study/img-div-02.webp",
  "/assets/images/kdc/study/ga4_study/img-div-03-1.webp",
  "/assets/images/kdc/study/ga4_study/img-div-03-2.gif",
  "/assets/images/kdc/study/ga4_study/img-div-04.webp",
];

const sqlStudyImages = [
  "/assets/images/kdc/study/sql_study/img-div-01.webp",
  "/assets/images/kdc/study/sql_study/img-div-02-1.webp",
  "/assets/images/kdc/study/sql_study/img-div-02-2.gif",
  "/assets/images/kdc/study/sql_study/img-div-03.webp",
  "/assets/images/kdc/study/sql_study/img-div-04.webp",
];

const appBuildStudyImages = [
  "/assets/images/kdc/study/appBuild_study/img-div-01.webp",
  "/assets/images/kdc/study/appBuild_study/img-div-02.webp",
  "/assets/images/kdc/study/appBuild_study/img-div-03.webp",
  "/assets/images/kdc/study/appBuild_study/img-div-04.webp",
  "/assets/images/kdc/study/appBuild_study/img-div-05.webp",
];

export const sqlStudyTutorSectionImages = {
  tutorIntro: "/assets/images/kdc/study/sql_study/session-tutor-intro.webp",
  goal: "/assets/images/kdc/study/sql_study/session-goal.webp",
};

export const ga4StudyTutorSectionImages = {
  tutorIntro: "/assets/images/kdc/study/ga4_study/session-tutor-intro.webp",
  goal: "/assets/images/kdc/study/ga4_study/session-goal.webp",
};

export const appBuildStudyTutorSectionImages = {
  tutorIntro:
    "/assets/images/kdc/study/appBuild_study/session-tutor-intro.webp",
  goal: "/assets/images/kdc/study/appBuild_study/session-goal.webp",
};

export const kdcPrices = {
  게임개발종합반: 49000,
  데이터분석종합반: 49000,
  앱개발종합반: 49000,
  금융인을위한파이썬업무자동화: 49000,
  웹개발종합반: 49000,
  웹개발의봄Spring: 49000,
  노코드종합반: 49000,
  "ChatGPT300%활용하기": 49000,
  AI가처음이어도쉽게배우는생성형AIAtoZ: 49000,
  클라우드가처음이어도쉽게배우는AWSAtoZ: 29000,
};

export type KdcStudy = {
  title: string;
  kdcCourseId: string;
  onlineCourseId: string;
  kdcCourseTitle: string;
  displayPrice: number;
  price: number;
  slug: string;
  templateVersion: number;
  studyComponent: string[];
  kdcUrl?: string;
  faqData?: any;
  introductionImages: string[];
  kdcTutorName: string;
  kdcTutorHistory: string[];
  kdcTutorThumbnail: string;
  tutorSection: {
    tutorIntro: string;
    goal: string;
  };
};

const sqlStudy: KdcStudy = {
  title: "비전공자도 쉽게 끝내는 SQL\n3주 완성 스터디",
  onlineCourseId: "64956fe61e7a5e8c1b9c6e50",
  kdcCourseId: "65d2b38b1076f1d4cc075361",
  kdcCourseTitle: "[왕초보] 엑셀보다 쉽고 빠른 SQL",
  kdcTutorName: "구다희",
  kdcTutorHistory: [
    "성균관대학교 졸",
    "E-commerce 업계 데이터 분석가로 재직중",
    "전) 바로고 데이터 사이언스 팀",
    "전) 레진 엔터테인먼트 미국전략실행팀",
  ],
  kdcTutorThumbnail: "/assets/images/kdc/study/sql_study/tutor-thumbnail.webp",
  displayPrice: 500000,
  price: 49000,
  slug: "sql_study",
  templateVersion: 1,
  studyComponent: [
    "온라인 강의 수강 (매주 1회 수강 인증)",
    "현직 튜터의 실시간 세션 1회",
  ],
  faqData: sqlStudyFaq,
  introductionImages: sqlStudyImages,
  tutorSection: sqlStudyTutorSectionImages,
};

const ga4Study: KdcStudy = {
  title: "마케터 필수 스터디!\nGA4 한번에 끝내기",
  onlineCourseId: "65b06c464b408d8c7a961a05",
  kdcCourseId: "65d2a90040ddbb5b4661f7b0",
  kdcCourseTitle: "[왕초보] GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  kdcTutorName: "이준성",
  kdcTutorHistory: [
    "[Do it! 구글애널리틱스4] 저자",
    "현) 플러스제로 COO",
    "전) 엔코드 데이터 팀장",
    "전) 네스프레소 한국 데이터 분석가",
  ],
  kdcTutorThumbnail: "/assets/images/kdc/study/ga4_study/tutor-thumbnail.webp",
  displayPrice: 500000,
  price: 49000,
  slug: "ga4_study",
  templateVersion: 1,
  studyComponent: [
    "온라인 강의 수강 (매주 1회 수강 인증)",
    "현직 튜터의 실시간 세션 1회",
  ],
  faqData: ga4StudyFaq,
  introductionImages: ga4StudyImages,
  tutorSection: ga4StudyTutorSectionImages,
};

const appBuildStudy: KdcStudy = {
  title: "노코드로 쉽게 배우는\n수익화 AI 앱 제작 스터디",
  onlineCourseId: "65b715cca5b5a5e6a309fe6a",
  kdcCourseId: "65d2acd2a645a72c5e4a08ad",
  kdcCourseTitle: "노코드로 수익형 AI 앱 만들기",
  kdcTutorName: "서형일",
  kdcTutorHistory: [
    "전) 500 Global 멘토",
    "전) 와이즐리 플랫폼 팀 리더",
    "전) 베이글랩스 소프트웨어 팀 리더",
    "전) ETRI(한국전자통신연구원)",
  ],
  kdcTutorThumbnail:
    "/assets/images/kdc/study/appBuild_study/tutor-thumbnail.webp",
  displayPrice: 500000,
  price: 49000,
  slug: "appBuild_study",
  templateVersion: 1,
  studyComponent: [
    "온라인 강의 수강 (매주 1회 수강 인증)",
    "현직 튜터의 실시간 세션 1회",
  ],
  faqData: appBuildStudyFaq,
  introductionImages: appBuildStudyImages,
  tutorSection: appBuildStudyTutorSectionImages,
};

export const kdcStudyData = [sqlStudy, ga4Study, appBuildStudy];

export type NbCourse = {
  kdcCourseId: string;
  onlineCourseId: string;
  originalTitle: string;
  hrdNetTitle: string;
  unifyingDisplayTitle: string;
  url: string;
  kdcKeyword: string;
  duration: number;
  displayPrice: number;
  price: number;
  tracseId: string;
  tag: "왕초보" | "스킬업";
  category: KdcCategoryType[];
  description?: string;
  desktopPopupImageX3: string;
  mobilePopupImageX3: string;
  desktopHeroImageX3: string;
  desktopInfoImageX3: string;
  desktopCurriculumImageX3: string;
  mobileHeroImageX3: string;
  mobileInfoImageX3: string;
  mobileCurriculumImageX3: string;

  desktopPopupImageX2: string;
  mobilePopupImageX2: string;
  desktopHeroImageX2: string;
  desktopInfoImageX2: string;
  desktopCurriculumImageX2: string;
  mobileHeroImageX2: string;
  mobileInfoImageX2: string;
  mobileCurriculumImageX2: string;
  previewLink: string;
  amount?: string;
  deadline?: Date;
  priority: number;
  setCourseId?: string;
  card_tags?: string[];

  productId?: number;
  isOff?: boolean;
};

export const gpt_300_online: NbCourse = {
  kdcCourseId: "64b687dc6e951768d4879e70",
  onlineCourseId: "654c451dd6aa5411421c298e",
  originalTitle: "ChatGPT 300% 활용하기",
  hrdNetTitle: "[왕초보] ChatGPT 300% 활용하기",
  unifyingDisplayTitle: "ChatGPT 300% 활용하기",
  url: "gpt_300_online",
  kdcKeyword: "GPT_300",
  duration: 6,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455855",
  tag: "왕초보",
  card_tags: ["실무활용", "최신 트렌드"],
  category: [KDC_MINOR_CATEGORY.AI_GPT, KDC_MINOR_CATEGORY.BEST],
  description:
    "업무에 쓰는 GPT 활용법은 따로 있어요. 지금 바로 내 생산성을 3배 높이세요!",
  amount: "6주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_curriculum_2x.webp`,
  priority: 100,
  previewLink: "https://www.youtube.com/embed/8uPBXCxnSXI?autoplay=1",
};

const sql_2023: NbCourse = {
  kdcCourseId: "65d2b38b1076f1d4cc075361",
  onlineCourseId: "64956fe61e7a5e8c1b9c6e50",
  originalTitle: "엑셀보다 쉽고 빠른 SQL",
  hrdNetTitle: "[왕초보] 엑셀보다 쉽고 빠른 SQL",
  unifyingDisplayTitle: "엑셀보다 쉽고 빠른 SQL",
  url: "sql_2023",
  kdcKeyword: "kdc_sql_v2",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459628",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.BEST, KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  description: "현직 데이터 분석가에게 실무에 필요한 SQL 스킬만 배우세요!",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/twjARPhs3dY?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_curriculum_2x.webp`,
  priority: 2,
  card_tags: ["실무활용"],
  productId: 8,
};

const web_basic: NbCourse = {
  kdcCourseId: "64af9cbb47cba538dd738f98",
  onlineCourseId: "649938ae1e7a5e8c1b9dfb37",
  originalTitle: "웹개발 종합반",
  hrdNetTitle: "[왕초보] 코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
  unifyingDisplayTitle: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
  url: "web_basic",
  kdcKeyword: "web_basic",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455893",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.BEST, KDC_MINOR_CATEGORY.DEV],
  description:
    "4만 명이 수강한 베스트 강의! 코딩이 처음이라면 이 강의로 시작하세요.",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/cCR0qiT4KpI?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_curriculum_2x.webp`,
  priority: 102,
  card_tags: ["왕초보 추천"],
};

const app: NbCourse = {
  kdcCourseId: "63ca26845b3bac30b29bf2a3",
  onlineCourseId: "5f0ae408765dae0006002817",
  originalTitle: "앱개발 종합반",
  hrdNetTitle: "[왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
  unifyingDisplayTitle: "플러터(Flutter)로 시작하는 앱개발 종합반",
  url: "app",
  kdcKeyword: "g_flutter",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000413063",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.BEST, KDC_MINOR_CATEGORY.DEV],
  description: "앱 기획부터 개발, 스토어 배포까지 A to Z를 함께 합니다.",
  previewLink: "https://www.youtube.com/embed/WkyigdyW3Qo?autoplay=1",
  amount: "5주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_curriculum_2x.webp`,
  priority: 4,
  card_tags: ["부수입 창출"],
  productId: 57,
};

const data_v2: NbCourse = {
  kdcCourseId: "63ca5b43dfbe98f4c8ef20bf",
  onlineCourseId: "6361d2ce4239a844b30d4163",
  originalTitle: "데이터 분석 종합반",
  hrdNetTitle: "[왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
  unifyingDisplayTitle: "마케터, 기획자를 위한 실전 데이터 분석",
  url: "data_v2",
  kdcKeyword: "g_data",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000413029",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  description: "데이터 기반 사고는 물론, 시각화 스킬까지 갖춰 보세요.",
  previewLink: "https://www.youtube.com/embed/nfESkVyxuM8?autoplay=1",
  amount: "5주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_curriculum_2x.webp`,

  priority: 5,
  card_tags: ["시각화", "대시보드"],
  productId: 49,
};

// const finance_automation: NbCourse = {
//   kdcCourseId: "62f09f2af3ce1804901b3f5c",
//   onlineCourseId: "62973372bf6e0e4f799fc76e",
//   originalTitle: "금융인을 위한\n파이썬 업무자동화",
//   hrdNetTitle: "[왕초보] 주식 데이터를 활용한 파이썬 데이터분석",
//   url: "finance_automation",
//   kdcKeyword: "finance_automation",
//   duration: 5,
//   displayPrice: 490000,
//   price: 49000,
//   tracseId: "AIG20220000409502",
//   tag: "왕초보",
//   category: [NbCategory.DATA, NbCategory.WORK_AUTOMATION],
//   getDesktopPopupImage: ()  =>
// `/     "${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_finance_automation_popup_2x.webp`,
//   getMobilePopupImage: (multiple : number) =>
// `/     "${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_finance_automation_popup_2x.webp`,
//   deadline: new Date("2024-07-29T21:00:00+09:00"),
//   priority: 6,
// };

const workflow_automation: NbCourse = {
  kdcCourseId: "65d2ae1b1076f1d4cc073fd0",
  originalTitle: "칼퇴를 부르는 파이썬 업무 자동화",
  onlineCourseId: "650abff5535c9f5382d509ee",
  hrdNetTitle: "[왕초보] 칼퇴를 부르는 파이썬 업무 자동화",
  unifyingDisplayTitle: "칼퇴를 부르는 파이썬 업무 자동화",
  url: "workflow_automation",
  kdcKeyword: "kdc_python_automation",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459619",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  previewLink: "https://www.youtube.com/embed/ZKXI-pYr390?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_curriculum_2x.webp`,
  priority: 7,
};

const marketing_ga4: NbCourse = {
  kdcCourseId: "65d2a90040ddbb5b4661f7b0",
  onlineCourseId: "65b06c464b408d8c7a961a05",
  originalTitle: "GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  hrdNetTitle: "[왕초보] GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  unifyingDisplayTitle: "GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  url: "marketing_ga4",
  kdcKeyword: "kdc_marketing_ga4",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459614",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION, KDC_MINOR_CATEGORY.BEST],
  description: "GA4와 SQL로 데이터에 숨겨진 고객의 '진짜 니즈'를 알아보세요!",
  amount: "4주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/uL__Xvi8RM4?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_curriculum_2x.webp`,
  priority: 8,
  card_tags: ["마케터 추천"],
};

const nocode_ai_app: NbCourse = {
  kdcCourseId: "65d2acd2a645a72c5e4a08ad",
  onlineCourseId: "65b715cca5b5a5e6a309fe6a",
  originalTitle: "노코드로 수익형 AI 앱 만들기",
  hrdNetTitle: "[왕초보] 노코드로 수익형 AI 앱 만들기",
  unifyingDisplayTitle: "노코드로 수익형 AI 앱 만들기",
  url: "nocode_ai_app",
  kdcKeyword: "kdc_nocode_ai_app",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459617",
  tag: "왕초보",
  category: [
    KDC_MINOR_CATEGORY.AI_GPT,
    KDC_MINOR_CATEGORY.DEV,
    KDC_MINOR_CATEGORY.BEST,
  ],
  description: "코딩 전혀 못해도 앱을 만들어 부수입 버는 방법을 배워 보세요.",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/yFEcjg7ERQg?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_curriculum_2x.webp`,
  priority: 9,
  card_tags: ["문과생 추천"],
  productId: 28,
};

const nocode_ai_automation: NbCourse = {
  kdcCourseId: "65d2a583af84d15811dc9aa2",
  onlineCourseId: "65b370c3949316d2d7a43847",
  originalTitle: "AI와 노코드로 업무 자동화 끝내기",
  hrdNetTitle: "[왕초보] AI와 노코드로 업무 자동화 끝내기",
  unifyingDisplayTitle: "AI와 노코드로 업무 자동화 끝내기",
  url: "nocode_ai_automation",
  kdcKeyword: "kdc_nocode_ai_automation",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459613",
  tag: "왕초보",
  category: [
    KDC_MINOR_CATEGORY.AI_GPT,
    KDC_MINOR_CATEGORY.DATA_AUTOMATION,
    KDC_MINOR_CATEGORY.BEST,
  ],
  description: "귀찮은 단순반복업무, AI로 쉽게 자동화하는 법을 배워보세요.",
  amount: "4주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/ZwQ-6a82BnY?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_curriculum_2x.webp`,
  priority: 10,
  card_tags: ["실무활용", "자동화 치트키"],
  isOff: true,
};

const webflow: NbCourse = {
  kdcCourseId: "64b0a2237be3bd117c4ec29a",
  onlineCourseId: "6412734baa727445ce9374b0",
  originalTitle: "노코드 종합반",
  hrdNetTitle: "[왕초보] 노코드로 완성도 있는 웹사이트 만들기",
  unifyingDisplayTitle: "노코드로 완성도 있는 웹사이트 만들기",
  url: "webflow",
  kdcKeyword: "webflow",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455905",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.BEST, KDC_MINOR_CATEGORY.AI_GPT],
  previewLink: "https://www.youtube.com/embed/-T28uLGq-xg?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_curriculum_2x.webp`,
  priority: 101,
  card_tags: ["문과생 추천"],
};

const ai_analytics_blockchain: NbCourse = {
  kdcCourseId: "65d2ad561076f1d4cc073cb0",
  onlineCourseId: "65baf6587e86cf32470c1784",
  originalTitle: "AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  hrdNetTitle:
    "[스킬업] AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  unifyingDisplayTitle:
    "AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  url: "ai_analytics_blockchain",
  kdcKeyword: "kdc_ai_analytics_blockchain",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459618",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT, KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  previewLink: "https://www.youtube.com/embed/EOlYdqo6HEE?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_curriculum_2x.webp`,
  priority: 12,
};

// const game: NbCourse = {
//   kdcCourseId: "62f09eebfeb2d564bacb0262",
//   onlineCourseId: "6083eaca6305e019d3e0c3b4",
//   originalTitle: "게임개발 종합반",
//   hrdNetTitle: "[왕초보] 유니티로 만드는 게임개발 종합반",
//   url: "game",
//   kdcKeyword: "gov_game",
//   duration: 5,
//   displayPrice: 490000,
//   price: 49000,
//   tracseId: "AIG20220000409493",
//   tag: "왕초보",
//   category: [NbCategory.DEV_BEGINNER],
// `/   getDesktopPopupImage: ()  =>  "${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_game_popup_2x.webp`,
// `/   getMobilePopupImage: (multiple : number) => "${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_game_popup_2x.webp`,
//   deadline: new Date("2024-07-29T21:00:00+09:00"),
//   priority: 13,
// };

const spring_v2: NbCourse = {
  kdcCourseId: "64b0a23c417100590a97e152",
  onlineCourseId: "648fc643f9b1fac7aced4966",
  originalTitle: "웹개발의 봄, Spring",
  hrdNetTitle: "[개발자 입문] 웹개발의 봄, Spring",
  unifyingDisplayTitle: "웹개발의 봄, Spring",
  url: "spring_v2",
  kdcKeyword: "spring_v2",
  duration: 4,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455897",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/pKLLXxSsS5s?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_curriculum_2x.webp`,
  priority: 103,
};

const interactive3dWeb: NbCourse = {
  kdcCourseId: "65d2ae8f1076f1d4cc07414b",
  originalTitle: "프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  onlineCourseId: "65b08e7f4b408d8c7a9625e9",
  hrdNetTitle: "[스킬업] 프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  unifyingDisplayTitle: "프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  url: "3d_interactive_web",
  kdcKeyword: "kdc_3d_interactive_web",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459620",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/iwx8nlfBfgo?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_curriculum_2x.webp`,
  priority: 15,
};

const d_unity: NbCourse = {
  kdcCourseId: "65d2af11a5c2b2029a102ecb",
  onlineCourseId: "65abb1f33cd250acb856433a",
  originalTitle: "Unity로 메타버스 3D 게임 만들기",
  hrdNetTitle: "[왕초보] Unity로 메타버스 3D 게임 만들기",
  unifyingDisplayTitle: "Unity로 메타버스 3D 게임 만들기",
  url: "3d_game",
  kdcKeyword: "kdc_3d_game",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459621",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/U89mbmx7Zwk?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_curriculum_2x.webp`,
  priority: 16,
};

const bio_app: NbCourse = {
  kdcCourseId: "65d2af70a5c2b2029a1030ca",
  onlineCourseId: "65bafc2e7e86cf32470c1dec",
  originalTitle: "Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  hrdNetTitle: "[왕초보] Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  unifyingDisplayTitle: "Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  url: "bio_app",
  kdcKeyword: "kdc_bio_app",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459622",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/W7ZTf6VALSM?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_curriculum_2x.webp`,
  priority: 17,
};

const cicd: NbCourse = {
  kdcCourseId: "65d2b1111076f1d4cc074c62",
  onlineCourseId: "65bc972922e51a142eced448",
  originalTitle: "Docker 기반 CI/CD 파이프라인 구축하기",
  hrdNetTitle: "[스킬업] Docker 기반 CI/CD 파이프라인 구축하기",
  unifyingDisplayTitle: "Docker 기반 CI/CD 파이프라인 구축하기",
  url: "cicd",
  kdcKeyword: "kdc_cicd",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459625",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV_WORK],
  previewLink: "https://www.youtube.com/embed/1kdBAv5xUtI?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_curriculum_2x.webp`,
  priority: 18,
};

const algorithm: NbCourse = {
  kdcCourseId: "65d2b315a5c2b2029a103d56",
  onlineCourseId: "6467063d187f226a50090d43",
  originalTitle: "실무에 바로 쓰이는 알고리즘 by Python",
  hrdNetTitle: "[스킬업] 실무에 바로 쓰이는 알고리즘 by Python",
  unifyingDisplayTitle: "실무에 바로 쓰이는 알고리즘 by Python",
  url: "algorithm",
  kdcKeyword: "kdc_algorithm",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459627",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV_WORK],
  previewLink: "https://www.youtube.com/embed/_XQF302-Kwk?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_curriculum_2x.webp`,
  priority: 19,
};

const cloud_aws: NbCourse = {
  kdcCourseId: "6653f2e84a59c95dcbf75cf8",
  originalTitle: "클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  onlineCourseId: "668c96d42bbb65f282155301",
  hrdNetTitle: "[스킬업] 클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  unifyingDisplayTitle: "클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  url: "cloud_aws",
  kdcKeyword: "certificate_aws_ssa",
  duration: 0,
  displayPrice: 290000,
  price: 29000,
  tracseId: "AIG20240000459623",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV_WORK],
  previewLink: "https://www.youtube.com/embed/fP_IwPM3KOU?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_cloud_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_cloud_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_cloud_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_cloud_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_curriculum_2x.webp`,
  priority: 20,
  card_tags: ["자격증 준비"],
};

const generative_ai: NbCourse = {
  kdcCourseId: "65d2b1e4a645a72c5e4a1f20",
  onlineCourseId: "65110da220492d270e776895",
  originalTitle: "AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  hrdNetTitle: "[스킬업] AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  unifyingDisplayTitle: "AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  url: "generative_ai",
  kdcKeyword: "kdc_generative_ai",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459626",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT],
  previewLink: "https://www.youtube.com/embed/1fPKKfG04PQ?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_curriculum_2x.webp`,
  priority: 21,
};

export const kdcCourseData: Record<string, NbCourse> = {
  app,
  workflow_automation,
  cicd,
  marketing_ga4,
  data_v2,
  // webflow,  TODO: kdc 판매 마감으로 인한 조치. 2024.12.31. 까지
  // spring_v2, TODO: kdc 판매 마감으로 인한 조치. 2024.12.31. 까지
  // spring: spring_v2,
  // web_basic, TODO: kdc 판매 마감으로 인한 조치. 2024.12.31. 까지
  // gpt_300_online, TODO: kdc 판매 마감으로 인한 조치. 2024.12.31. 까지
  ai_analytics_blockchain,
  nocode_ai_app,
  nocode_ai_automation,
  algorithm,
  bio_app,
  "3d_interactive_web": interactive3dWeb,
  "3d_unity": d_unity,
  "3d_game": d_unity,
  cloud_aws,
  generative_ai,
  sql_2023,
  sql_v2: sql_2023,
};

export const kdcCourseArray: NbCourse[] = Object.values(kdcCourseData)
  .filter(
    (course, index, self) =>
      index === self.findIndex((t) => t.kdcCourseId === course.kdcCourseId),
  )
  .filter((course) => !course.isOff)
  .filter((course) => {
    const currentDate = new Date();
    return !course.deadline || course.deadline > currentDate;
  });

export const kdcPopularCourseArray: NbCourse[] = kdcCourseArray.sort(
  (a, b) => a.priority - b.priority,
);

export const aiSpecialCourseArray: NbCourse[] = kdcCourseArray.filter(
  (course) =>
    course.category.includes(KDC_MINOR_CATEGORY.AI_GPT) &&
    course.url !== "gpt_300_online",
);
